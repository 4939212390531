import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Home';
// import './Components/App.css';

function App() {
  return (
        <div className="container-fluid h-100 g-0">
          <Home />
        </div>
  );
  }

export default App;
